import { Component, Vue, Prop } from "vue-property-decorator";
import { TeamMember } from "@/types/team";
import { Currency } from "@/types/currency";
import { HtmlElementClassName } from "@/types/element";

const components = {
  AutoRefillForm: () =>
    import("../../../../common/components/AutoRefillForm/AutoRefillForm.vue"),
};

@Component({
  inheritAttrs: false,
  components,
})
export default class AutoRefillMenu extends Vue {
  @Prop({ type: Boolean, default: false }) private readonly disabled!: boolean;
  @Prop({ type: String, required: true }) private readonly email!: string;
  @Prop({ type: Array, required: true })
  private readonly currencies!: Currency[];
  @Prop({ type: Object })
  private readonly settings?: TeamMember["autoRefillSettings"];
  @Prop({ type: Object })
  private readonly balance?: TeamMember["balance"];

  private showedMenu = false;

  private get htmlElementClassName() {
    return {
      teamAutoRefillButton: HtmlElementClassName.teamAutoRefillButton,
    };
  }

  private get autoRefillEnabled() {
    return (
      !!this.settings &&
      Object.values(this.settings).some((item) => !!item?.autoRefillEnabled)
    );
  }

  private toggleMenu(showed = !this.showedMenu) {
    this.showedMenu = showed;
  }

  private async onSuccess() {
    this.$emit("success");
    this.toggleMenu(false);
  }

  private mounted() {
    components.AutoRefillForm();
  }
}
